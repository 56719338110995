import axiosInstance from '../core/interceptors/authInterceptor';
import { IsTeamsTab } from '../index';

interface ISubmitErrorMessagePayload {
  firstName: string | undefined;
  lastName: string | undefined;
  company?: string;
  reason?: string;
  email?: string;
  managerEmail?: string;
  message: string;
}

const submitErrorMessage = (payload: ISubmitErrorMessagePayload) =>
  axiosInstance.post(IsTeamsTab() ? `/help/teams` : `/help`, {
    lastName: payload.lastName,
    firstName: payload.firstName,
    managerEmail: payload.managerEmail,
    mail: payload.email,
    message: payload.message,
    company: payload.company,
    reason: payload.reason,
  });

export { submitErrorMessage };
