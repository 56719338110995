import { Flex, Spin } from 'antd';

const Spinner = () => {
  return (
    <Flex align="center" justify="center">
      <Spin size="large" />
    </Flex>
  );
};

export default Spinner;
