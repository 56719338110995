import { Suspense, memo } from 'react';
import { Navigate, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, SpeedDial, useMediaQuery } from '@mui/material';
import { selectAccount } from 'state/accountSlice';
import Restricted from '../contexts/permissionProvider/Restricted';
import { AccountRoleEnum, PermissionEnum } from '../enums/accountEnum';
import { theme } from '../theme';
import ProtectedRoute from './routesProtections/ProtectedRoute';
import Group from 'assets/images/Group.svg';
import { isSubset } from 'core/helpers/arrayHelpers';
import { DefaultLayout } from 'layouts/DefaultLayout';
import DefaultLayoutStore from 'layouts/DefaultLayout.store';
import { Screens } from './Screens';
import { Hoc } from './Hoc';

const Routes = () => {
  const { account } = useSelector(selectAccount);
  const { role } = account;
  const displaySpeedDial = isSubset(account.permissions, [PermissionEnum.RECOGNITION]);
  const navigate = useNavigate();
  const displayNoneUpMdStyle = {
    display: useMediaQuery(theme.breakpoints.up('md')) ? 'none' : 'inherit',
  };
  const { settings } = DefaultLayoutStore.useStore((state) => state);

  const MobileSpeedDial = memo(() =>
    displaySpeedDial ? (
      <SpeedDial
        ariaLabel="SpeedDial"
        sx={{ position: 'fixed', bottom: 50, right: 16, zIndex: 1300, ...displayNoneUpMdStyle }}
        icon={<img src={Group} />}
        onClick={() => navigate(Screens.PeerPecognition.path)}
        FabProps={{
          sx: {
            background: 'linear-gradient(180deg, #9C87EB 0%, #532BE3 100%)',
            boxShadow: '0px 4px 4px 0px #6C44FF4D',
          },
        }}
      ></SpeedDial>
    ) : null
  );

  MobileSpeedDial.displayName = 'MobileSpeedDial';
  const location = useLocation();

  const routes = useRoutes([
    {
      path: '/',
      children: [
        {
          path: Screens.Home.path,
          element: (
            <ProtectedRoute>
              <>
                <MobileSpeedDial />
                <Screens.Home.Component />
              </>
            </ProtectedRoute>
          ),
          children: [
            {
              path: Screens.PostIdDetails.path,
              element: (
                <ProtectedRoute>
                  <Screens.PostIdDetails.Component />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: Screens.SpendingTokens.path,
          element: (
            <ProtectedRoute>
              <Screens.SpendingTokens.Component />
            </ProtectedRoute>
          ),
          children: [
            {
              path: Screens.MyCollectionTokenId.path,
              element: (
                <ProtectedRoute>
                  <Screens.MyCollectionTokenId.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.MyCollection.path,
              element: (
                <ProtectedRoute>
                  <Screens.MyCollection.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: Screens.Marketplace.path,
              element: (
                <ProtectedRoute>
                  <Screens.Marketplace.Component />
                </ProtectedRoute>
              ),
            },
            {
              path: `${Screens.Happening.path}/:challengeId?`,
              element: (
                <ProtectedRoute>
                  <Screens.Happening.Component />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: Screens.HappeningInProgress.path,
          element: (
            <ProtectedRoute>
              <Screens.HappeningInProgress.Component />
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.HappeningClosed.path,
          element: (
            <ProtectedRoute>
              <Screens.HappeningClosed.Component />
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.HappeningClosed.path,
          element: (
            <ProtectedRoute>
              <Screens.HappeningClosed.Component />
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.HappeningClosed.path,
          element: (
            <ProtectedRoute>
              <Screens.HappeningClosed.Component />
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.Profile.path,
          element: (
            <ProtectedRoute>
              <Hoc
                key={'profile'}
                settings={{
                  ...settings,
                  hasRightSideBarContent: false,
                  RightSideBarContent: () => null,
                }}
              >
                <Screens.Profile.Component />
              </Hoc>
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.ProfileEdit.path,
          element: (
            <ProtectedRoute>
              <Hoc
                key={'profile_edits'}
                settings={{
                  ...settings,
                  title: '',
                  hasRightSideBarContent: true,
                  RightSideBarContent: () => null,
                }}
              >
                <Screens.ProfileEdit.Component />
              </Hoc>
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.ProfileNotifications.path,
          element: (
            <ProtectedRoute>
              <Hoc
                key={'profile_notifications'}
                settings={{
                  ...settings,
                  title: '',
                  hasRightSideBarContent: true,
                  RightSideBarContent: () => null,
                }}
              >
                <Screens.ProfileNotifications.Component />
              </Hoc>
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.ProfilePassword.path,
          element: (
            <ProtectedRoute>
              <Hoc
                key={'profile_password'}
                settings={{
                  ...settings,
                  title: '',
                  hasRightSideBarContent: true,
                  RightSideBarContent: () => null,
                }}
              >
                <Screens.ProfilePassword.Component />
              </Hoc>
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.ProfileLanguage.path,
          element: (
            <ProtectedRoute>
              <Hoc
                key={'profile_languages'}
                settings={{
                  ...settings,
                  title: '',
                  hasRightSideBarContent: true,
                  RightSideBarContent: () => null,
                }}
              >
                <Screens.ProfileLanguage.Component />
              </Hoc>
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.Suggestions.path,
          element: (
            <ProtectedRoute>
              <Hoc
                key={'suggestions'}
                settings={{
                  ...settings,
                  title: '',
                  hasRightSideBarContent: false,
                  RightSideBarContent: () => null,
                }}
              >
                <Screens.Suggestions.Component />
              </Hoc>
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.PeerPecognition.path,
          element: (
            <ProtectedRoute
              privilege={[AccountRoleEnum.ISSUER, AccountRoleEnum.HOLDER]}
              role={role?.toUpperCase() as AccountRoleEnum}
            >
              <Restricted to={[PermissionEnum.RECOGNITION]}>
                <Hoc
                  key={'peer_recognition'}
                  settings={{
                    ...settings,
                    title: '',
                    hasRightSideBarContent: false,
                    RightSideBarContent: () => <>JAUGEs</>,
                  }}
                >
                  <Screens.PeerPecognition.Component />
                </Hoc>
              </Restricted>
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.MarketplaceBrandsId.path,
          element: (
            <ProtectedRoute
              privilege={[AccountRoleEnum.ISSUER, AccountRoleEnum.HOLDER]}
              role={role?.toUpperCase() as AccountRoleEnum}
            >
              <Restricted to={[PermissionEnum.MARKETPLACE_GIFT_CARD]}>
                <Hoc
                  key={'marketplace_brands'}
                  settings={{
                    ...settings,
                    title: '',
                    hasRightSideBarContent: false,
                    RightSideBarContent: () => null,
                  }}
                >
                  <Screens.MarketplaceBrandsId.Component />
                </Hoc>
              </Restricted>
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.MarketplacePurchaseApproveId.path,
          element: (
            <Navigate
              to="/marketplace/purchase/approve"
              state={{ prevPath: location.pathname }}
              replace
            />
          ),
        },
        {
          path: Screens.MarketplaceBrands.path,
          element: (
            <ProtectedRoute>
              <Restricted to={[PermissionEnum.MARKETPLACE_GIFT_CARD]}>
                <Hoc
                  key={'marketplace_brands'}
                  settings={{
                    ...settings,
                    hasRightSideBarContent: false,
                    RightSideBarContent: () => null,
                  }}
                >
                  <Screens.MarketplaceBrands.Component />
                </Hoc>
              </Restricted>
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.Charities.path,
          element: (
            <ProtectedRoute>
              <Restricted to={[PermissionEnum.MARKETPLACE_ASSOCIATION]}>
                <Screens.Charities.Component />
              </Restricted>
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.CompanyAdvantages.path,
          element: (
            <ProtectedRoute>
              <Restricted to={[PermissionEnum.MARKETPLACE_PROSUMER]}>
                <Hoc
                  key={'company_advantages'}
                  settings={{
                    ...settings,
                    hasRightSideBarContent: false,
                    RightSideBarContent: () => null,
                  }}
                >
                  <Screens.CompanyAdvantages.Component />
                </Hoc>
              </Restricted>
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.CompanyAdvantagesId.path,
          element: (
            <ProtectedRoute>
              <Restricted to={[PermissionEnum.MARKETPLACE_PROSUMER]}>
                <Hoc
                  key={'company_advantages_id'}
                  settings={{
                    ...settings,
                    hasRightSideBarContent: false,
                    RightSideBarContent: () => null,
                  }}
                >
                  <Screens.CompanyAdvantagesId.Component />
                </Hoc>
              </Restricted>
            </ProtectedRoute>
          ),
        },
        {
          path: Screens.Unity.path,
          element: (
            <Hoc
              key={'company_advantages_id'}
              settings={{
                ...settings,
                hasRightSideBarContent: true,
                RightSideBarContent: () => (
                  <Box sx={{ height: '100%', width: '100%', background: 'red' }}>UNITY</Box>
                ),
              }}
            >
              <Screens.Unity.Component />
            </Hoc>
          ),
        },
      ],
      element: (
        <Suspense>
          <DefaultLayout />
        </Suspense>
      ),
    },
    {
      path: Screens.PostIdDetailsStandAlone.path,
      element: (
        <ProtectedRoute>
          <Screens.PostIdDetailsStandAlone.Component />
        </ProtectedRoute>
      ),
    },
    { path: '*', element: <Navigate to="/notFound" replace /> },

    {
      path: Screens.NotFound.path,
      element: (
        <Suspense>
          <Screens.NotFound.Component />
        </Suspense>
      ),
    },
    {
      path: Screens.Denied.path,
      element: (
        <Suspense>
          <Screens.Denied.Component />
        </Suspense>
      ),
    },
    {
      path: Screens.ErrorCompanyNotKnown.path,
      element: (
        <Suspense>
          <Screens.ErrorCompanyNotKnown.Component />
        </Suspense>
      ),
    },
    {
      path: Screens.Revoked.path,
      element: (
        <Suspense>
          <Screens.Revoked.Component />
        </Suspense>
      ),
    },
  ]);
  return routes;
};

export { Routes };
